import * as React from "react"
import Grid from "@material-ui/core/Grid"

import { makeStyles } from "@material-ui/core/styles"

import { UserContext, FeedbackContext } from "../../contexts"

import Login from "./Login"

const useStyles = makeStyles(theme => ({
  innerContainer: {
    width: "50rem",
  },
  mainContainer: {
    paddingTop: "8.5rem",
    paddingBottom: "4.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",

    minHeight: "calc(100svh  - 64px)",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "@global": {
    body: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
  },
}))

const AuthPortal = ({ location }) => {
  const classes = useStyles()
  const [selectedStep, setSelectedStep] = React.useState(0)
  const { user, dispatchUser } = React.useContext(UserContext)
  const { feedback, dispatchFeedback } = React.useContext(FeedbackContext)

  const steps = [{ component: Login, label: "Login" }]

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      classes={{ root: classes.mainContainer }}
    >
      <Grid item classes={{ root: classes.innerContainer }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {steps.map((Step, i) =>
            selectedStep === i ? (
              <Step.component
                setSelectedStep={setSelectedStep}
                steps={steps}
                user={user}
                dispatchUser={dispatchUser}
                feedback={feedback}
                dispatchFeedback={dispatchFeedback}
                location={location}
                key={i}
              />
            ) : null
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AuthPortal
